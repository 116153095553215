import { SlSettingsValues } from "../../QueueForm/controls/SlSettingsInput"
import {
    CreateQueueRequest,
    FinishedDialogsDailySettings,
    FinishedDialogsSettings,
    QueueType,
    SlSettings,
    TimeoutSettings,
    UpdateQueueRequest,
    WaitTimeSettings
} from "../../../models/queue"
import { Time } from "../../../utility/common/time"
import { MetricToAdd, MetricValue, QueueValues } from "../../../models/queueValues"
import { ItemId } from "@atlaskit/tree/types"
import { processExtraSettings } from "../../../utility/queues/processExtraSettings"
import { TimeoutSettingsValues } from "../../QueueForm/controls/TimeoutSettingsInput"
import { BandwidthGroup } from "../../../models/projectSettings"
import AsyncState from "../../../core/asyncState"

const getTimeoutSettings = (values: TimeoutSettingsValues): TimeoutSettings => {
    return {
        TimeoutQueueId: values.timeoutQueue,
        TimeoutMSec:
            Time.hoursToMs(values.timeoutHr) + Time.minutesToMs(values.timeoutMin) + Time.secondsToMs(values.timeoutSec)
    }
}

const getSlSettings = (values: SlSettingsValues): SlSettings => {
    return {
        SlThresholdMSec: values.slThresholdSec * 1000,
        SlPeriodMSec: Time.hoursToMs(values.slPeriodHr) + Time.minutesToMs(values.slPeriodMin),
        SlTarget: values.slTarget
    }
}

const processMetrics = (metrics: MetricValue[]) => {
    const awtStatsSettings: WaitTimeSettings[] = []
    const finishedDialogsSettings: FinishedDialogsSettings[] = []
    const finishedDialogsDailySettings: FinishedDialogsDailySettings[] = []

    metrics.forEach(metric => {
        switch (metric.type) {
            case MetricToAdd.Awt:
                awtStatsSettings.push({
                    Id: metric.value.id,
                    LookbehindMSec: Time.hoursToMs(metric.value.hr) + Time.minutesToMs(metric.value.min)
                })
                break
            case MetricToAdd.FinishedDialogs:
                finishedDialogsSettings.push({
                    Id: metric.value.id,
                    LookbehindMSec: Time.hoursToMs(metric.value.hr) + Time.minutesToMs(metric.value.min)
                })
                break
            case MetricToAdd.FinishedDialogsDaily:
                finishedDialogsDailySettings.push({
                    Id: metric.value.id,
                    LocalResetTime: Time.hoursToMs(metric.value.hr) + Time.minutesToMs(metric.value.min)
                })
        }
    })

    return {
        awtStatsSettings,
        finishedDialogsSettings,
        finishedDialogsDailySettings
    }
}

export const buildCreateQueueRequest = (
    values: QueueValues,
    categoryId: ItemId,
    bandwidthGroups: AsyncState<BandwidthGroup[]>,
    allDialogGroupsSetting: string
): CreateQueueRequest => {
    const { awtStatsSettings, finishedDialogsSettings, finishedDialogsDailySettings } = processMetrics(values.metrics)
    const {
        dialogTimeoutSettings,
        operatorAutoGreeting,
        clientFormSurvey,
        additionalResendConfig,
        emailChannels,
        fastReplyCatalogId,
        autoHolding,
        allowCatalogs,
        groups,
        restrictedQueues,
        dialogFinishSettings
    } = processExtraSettings(values.extraSettings, bandwidthGroups, allDialogGroupsSetting)

    return {
        General: {
            Name: values.name,
            Type: QueueType.Auto,
            TaskSize: values.taskSize,
            Priority: values.priority,
            TimeoutSettings: values.timeoutValues && getTimeoutSettings(values.timeoutValues),
            SlSettings: values.slValues && getSlSettings(values.slValues),
            CategoryId: String(categoryId),
            ...(awtStatsSettings.length && { AwtStatsSettings: awtStatsSettings }),
            ...(finishedDialogsSettings.length && { FinishedDialogsSettings: finishedDialogsSettings }),
            ...(finishedDialogsDailySettings.length && { FinishedDialogsDailySettings: finishedDialogsDailySettings })
        },
        ExtendedSettings: {
            DialogTimeoutSettings: dialogTimeoutSettings,
            DialogFinishSettings: dialogFinishSettings,
            OperatorAutoGreeting: operatorAutoGreeting,
            ClientFormSurvey: clientFormSurvey,
            AdditionalResendConfig: additionalResendConfig,
            EmailChannels: emailChannels,
            KnowledgeBase: {
                CatalogId: fastReplyCatalogId
            },
            AutoHoldingSettings: autoHolding,
            AllowCatalogs: allowCatalogs,
            DialogGroups: groups,
            RestrictedQueues: restrictedQueues
        }
    }
}

export const buildUpdateQueueRequest = (
    values: QueueValues,
    categoryId: ItemId,
    bandwidthGroups: AsyncState<BandwidthGroup[]>,
    allDialogGroupsSetting: string
): UpdateQueueRequest => {
    const { awtStatsSettings, finishedDialogsSettings, finishedDialogsDailySettings } = processMetrics(values.metrics)
    const {
        dialogTimeoutSettings,
        operatorAutoGreeting,
        clientFormSurvey,
        additionalResendConfig,
        emailChannels,
        fastReplyCatalogId,
        autoHolding,
        allowCatalogs,
        groups,
        restrictedQueues,
        dialogFinishSettings
    } = processExtraSettings(values.extraSettings, bandwidthGroups, allDialogGroupsSetting)

    return {
        General: {
            Name: values.name,
            Type: QueueType.Auto,
            TaskSize: values.taskSize,
            Priority: values.priority,
            TimeoutSettings: values.timeoutValues ? getTimeoutSettings(values.timeoutValues) : null,
            SlSettings: values.slValues ? getSlSettings(values.slValues) : null,
            AwtStatsSettings: awtStatsSettings.length ? awtStatsSettings : null,
            FinishedDialogsSettings: finishedDialogsSettings.length ? finishedDialogsSettings : null,
            FinishedDialogsDailySettings: finishedDialogsDailySettings.length ? finishedDialogsDailySettings : null,
            RoutingWebHook: null,
            CategoryId: String(categoryId)
        },
        ExtendedSettings: {
            DialogTimeoutSettings: dialogTimeoutSettings,
            DialogFinishSettings: dialogFinishSettings,
            OperatorAutoGreeting: operatorAutoGreeting,
            ClientFormSurvey: clientFormSurvey,
            AdditionalResendConfig: additionalResendConfig,
            EmailChannels: emailChannels,
            KnowledgeBase: {
                CatalogId: fastReplyCatalogId
            },
            AutoHoldingSettings: autoHolding,
            AllowCatalogs: allowCatalogs,
            DialogGroups: groups,
            RestrictedQueues: restrictedQueues
        }
    }
}
