import { useTranslation } from "react-i18next"
import { useActions } from "../../hooks/useAction"
import DialogSystem from "../DialogSystem/DialogSystem"
import { ModalBox } from "../ModalBox/ModalBox"
import styles from "./DialogTransferModal.module.scss"
import { useAppSelector } from "../../store/hooks"
import { selectDialogId } from "../../store/dialogs/selectors"
import { DialogTransferModalForm, EDialogTransferType, TDialogTransferModalFormValues } from "./DialogTransferModalForm"
import {
    useOperatorDialogTransferToOperatorMutation,
    useOperatorDialogTransferToQueueMutation
} from "../../api/controllers/dialogs"
import { selectorStrictReturnsGuard } from "../../helpers/types"
import { actions } from "../../store/dialogs/slice"
import { useDispatch } from "react-redux"

export const DIALOG_TRANSFER_DIALOG_ID = "DIALOG_TRANSFER_DIALOG_ID"

const tNamespace = "dialogs:transfer"

export type IDialogTransferModalProps = Partial<React.ComponentPropsWithRef<typeof DialogSystem>> & { queueId?: string }

export type TDialogMeta = {
    omniUserId: string
}

export const DialogTransferModal: React.FC<IDialogTransferModalProps> = ({ ...props }) => {
    const { hideDialog } = useActions()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [runOperatorDialogTransferToQueue] = useOperatorDialogTransferToQueueMutation()
    const [runOperatorDialogTransferToOperator] = useOperatorDialogTransferToOperatorMutation()
    const selectedDialogId = useAppSelector(selectorStrictReturnsGuard(selectDialogId))

    const handleModalBoxClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()

    const handleOperatorDialogTransfer = async (formData: TDialogTransferModalFormValues) => {
        if (formData.transferType === EDialogTransferType.Queue) {
            const { transferMessage, selectedQueueId } = formData

            dispatch(actions.closeFastRepliesSidebar())
            await runOperatorDialogTransferToQueue({
                Message: transferMessage,
                QueueId: selectedQueueId,
                DialogId: selectedDialogId
            })
        }

        if (formData.transferType === EDialogTransferType.Operator) {
            const { transferMessage, queueIdOnOperatorRefused, selectedOperatorId } = formData

            await runOperatorDialogTransferToOperator({
                Message: transferMessage,
                QueueId: queueIdOnOperatorRefused,
                DialogId: selectedDialogId,
                OperatorId: selectedOperatorId
            })
        }

        hideDialog({
            dialogId: DIALOG_TRANSFER_DIALOG_ID
        })
    }

    return (
        <DialogSystem {...props} dialogId={DIALOG_TRANSFER_DIALOG_ID}>
            <div className={styles.dialogTransferModal}>
                <ModalBox
                    className={styles.dialogTransferModal__window}
                    dialogTitle={t(`${tNamespace}.transferDialog`)}
                    actions={[]}
                    onClick={handleModalBoxClick}
                >
                    <DialogTransferModalForm
                        queueId={props.queueId}
                        onSubmitForm={handleOperatorDialogTransfer}
                        includeCurrentUser={false}
                        isInDialog
                    />
                </ModalBox>
            </div>
        </DialogSystem>
    )
}
