import { forwardRef, useCallback, useEffect, useMemo } from "react"
import styles from "./DialogTransferOperatorSearchDropdown.module.scss"
import {
    dialogsApi,
    useLazyGetDialogTransferAvailableQueuesQuery,
    useLazySearchOperatorByCriterionQuery
} from "../../../api/controllers/dialogs"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    DialogTransferOperatorListItem,
    DialogTransferOperatorListItemProps
} from "./DialogTransferOperatorListItem/DialogTransferOperatorListItem"
import { useTranslation } from "react-i18next"
import {
    DialogTransferOperatorSearchFilters,
    TSearchFiltersFormValues
} from "./DialogTransferOperatorSearchFilters/DialogTransferOperatorSearchFilters"
import { selectDialogId, selectSearchOperatorByCriterionPrevArgs } from "../../../store/dialogs/selectors"
import { useParams } from "react-router-dom"
import AsyncQuery from "../../Async/AsyncQuery"
import Spinner from "../../Spinner/Spinner"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import { selectGetRolesState } from "../../../store/roles/selectors"
import { getRoles } from "../../../store/roles/thunks"
import { actions } from "../../../store/roles/slice"
import { mapResponseQueuesToGroupedQueue, mapResponseRolesToListRoles } from "../helpers"
import { useSelector } from "react-redux"
import { selectOperatorStatuses } from "../../../store/userOperator/selectors"
import { OperatorStatusValue } from "../../../models/operatorStatus"

const tNamespace = "dialogs:transfer."

export interface IDialogTransferOperatorSearchDropdownProps {
    onSelectOperator: DialogTransferOperatorListItemProps["onSelect"]
    queryCriterion: string
    includeCurrentUser: boolean
    channelId?: string
    queueId?: string
}

export const DialogTransferOperatorSearchDropdown = forwardRef<
    HTMLDivElement,
    IDialogTransferOperatorSearchDropdownProps
>((props, innerRef) => {
    const { onSelectOperator, queryCriterion, channelId, includeCurrentUser, queueId } = props
    const { projectId } = useParams<{ projectId: string }>()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const rolesAsyncState = useAppSelector(selectGetRolesState)
    const [getAvailableQueues, getAvailableQueuesQuery] = useLazyGetDialogTransferAvailableQueuesQuery()

    const selectedDialogId = useAppSelector(selectDialogId) as string
    const { data: dialogData } = useAppSelector(dialogsApi.endpoints.getDialog.select(selectedDialogId))

    const internalProjectId = dialogData?.Project.Id ?? projectId

    const [searchOperatorByCriterionTrigger, searchOperatorByCriterionQuery] = useLazySearchOperatorByCriterionQuery()

    const searchOperatorByCriterionPrevArgs = useAppSelector(selectSearchOperatorByCriterionPrevArgs)

    const handleFiltersFormAutosubmit = (filtersFormData: TSearchFiltersFormValues) =>
        searchOperatorByCriterionTrigger({
            projectId: internalProjectId,
            query: queryCriterion,
            ...filtersFormData,
            includeCurrentUser: includeCurrentUser
        })

    useEffect(() => {
        searchOperatorByCriterionTrigger({
            ...searchOperatorByCriterionPrevArgs,
            query: queryCriterion,
            projectId: internalProjectId,
            includeCurrentUser: includeCurrentUser
        })
    }, [queryCriterion, internalProjectId, includeCurrentUser])

    const setupOptions = useCallback(async () => {
        if (!queueId) return

        await getAvailableQueues({
            projectId: internalProjectId,
            channelId,
            queueId
        })
        await dispatch(getRoles(internalProjectId))
    }, [dispatch, getAvailableQueues, internalProjectId, dialogData?.Channel.Id])

    useEffect(() => {
        setupOptions()

        return () => {
            dispatch(actions.getRolesReset())
        }
    }, [setupOptions, dispatch])

    const { query: _, ...filtersInitial } = searchOperatorByCriterionPrevArgs

    const availableQueuesOptions = useMemo(
        () => mapResponseQueuesToGroupedQueue(getAvailableQueuesQuery.data?.Categories),
        [getAvailableQueuesQuery.data?.Categories]
    )

    const rolesOptions = useMemo(() => mapResponseRolesToListRoles(rolesAsyncState.data), [rolesAsyncState.data])

    const allStatuses = useSelector(selectOperatorStatuses)
    const checkOperatorIsInactive = (operatorStatus: string) => {
        const currentStatus = allStatuses.find(status => status.Description === operatorStatus)
        return currentStatus?.Value === OperatorStatusValue.Offline
    }

    return (
        <div className={styles.dropdown} ref={innerRef}>
            <DialogTransferOperatorSearchFilters
                initialData={filtersInitial}
                rolesOptions={rolesOptions}
                availableQueuesOptions={availableQueuesOptions}
                onSubmitForm={handleFiltersFormAutosubmit}
            />
            {rolesOptions.length ? (
                <AsyncQuery
                    query={searchOperatorByCriterionQuery}
                    processView={<Spinner size={30} />}
                    emptyDataView={
                        <div className={styles.dropdown__empty}>{t(`${tNamespace}search-operator-not-found`)}</div>
                    }
                    errorView={({ message }) => <ErrorMessage text={message} />}
                    validator={query => !!query.data?.length}
                >
                    {({ data }) => (
                        <div className={styles.dropdown__list}>
                            {data.map((op, index) => (
                                <DialogTransferOperatorListItem
                                    key={op.OperatorId}
                                    operator={op}
                                    roles={rolesOptions}
                                    onSelect={onSelectOperator}
                                    isInactiveOperator={checkOperatorIsInactive(op.Status)}
                                    /*
                                      Теперь бэкэнд, при флаге IncludeCurrentUser: true
                                      всегда первым возвращает самого оператора
                                    */
                                    isHimSelf={index === 0 && includeCurrentUser}
                                />
                            ))}
                        </div>
                    )}
                </AsyncQuery>
            ) : null}
        </div>
    )
})
